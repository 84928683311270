import {
  IRegional,
  IRegionalListRequest,
  IRegionalStoreRequest
} from "../models/regionals";
import ApiBase from "./ApiBase";

const USER_PATH = "/dash";
const ADMIN = "/admin";
const REGINALS = "/regionals";

/**
 * Retorna as regionais
 * @param {number} page  - numero da página
 * @param {number} offset - quantidade de itens por página
 * @returns {IRegionalListRequest}
 */
export const RegionalsList = async (
  page: number,
  offset: number
): Promise<IRegionalListRequest> => {
  const res = await ApiBase.get(
    `${USER_PATH + ADMIN + REGINALS}?offset=${offset}&page=${page}`
  );
  return res?.data as IRegionalListRequest;
};

/**
 * Cria uma nova regional
 * @param {IRegionalStoreRequest} regional - Dados da reginonal
 * @returns void
 */
export const RegionalsStore = async (
  regional: IRegionalStoreRequest
): Promise<void> => {
  const res = await ApiBase.post(`${USER_PATH + ADMIN + REGINALS}`, regional);
  return res?.data;
};



/**
 * 
 * @param {number} id - id da regional
 * @returns 
 */
export const ReginalsShow = async (id: number): Promise<IRegional> => {
  const res = await ApiBase.get(`${USER_PATH + ADMIN + REGINALS}/${id}`);
  return res?.data as IRegional;
};
