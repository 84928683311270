import {
  IBoxesListRequest,
  IBoxShowOneRequest,
  IBoxStoreRequest
} from "../models/box";
import ApiBase from "./ApiBase";

const USER_PATH = "/dash";
const ADMIN = "/admin";
const BOXES = "/boxes";
const FINDBYCODE = "/findbycode";
/**
 * Exibe os dados de Caixa
 * @returns { IBoxesListRequest } - Lista os dados de Caixas
 */
export const BoxListRequest = async (
  page: number,
  offset: number
): Promise<IBoxesListRequest> => {
  const res = await ApiBase.get(
    `${USER_PATH}${ADMIN}${BOXES}?offset=${offset}&page=${page}`
  );

  return res?.data as IBoxesListRequest;
};

/**
 * Seleciona a Caixa de acordo com o id informado
 * @param { number } id - Id da Caixa para seleção
 * @returns { IBoxShowOneRequest } - Caixa com o id informado
 */
export const BoxFetchOneRequest = async (
  id: number
): Promise<IBoxShowOneRequest> => {
  const res = await ApiBase.get(`${USER_PATH}${ADMIN}${BOXES}/${id}`);
  return res?.data as IBoxShowOneRequest;
};

/**
 * Cria uma caixa
 * @param {IBoxStoreRequest} box - Dados da Caixa
 * @returns { IBoxesListRequest } - Lista os dados de Caixas
 */
export const BoxStoreRequest = async (
  box: IBoxStoreRequest
): Promise<IBoxesListRequest[] | []> => {
  const res = await ApiBase.post(`${USER_PATH}${ADMIN}${BOXES}`, box);
  return res?.data?.data as IBoxesListRequest[];
};

/**
 * Seleciona a Caixa de acordo com o codigo informado
 * @param { string } code - Id da Caixa para seleção
 * @returns { IBoxShowOneRequest } - Caixa com o id informado
 */
export const BoxShowByCode = async (
  code: string
): Promise<IBoxShowOneRequest> => {
  const res = await ApiBase.get(
    `${USER_PATH}${ADMIN}${BOXES}${FINDBYCODE}/${code}`
  );
  return res?.data as IBoxShowOneRequest;
};
