
import { Vue, Component } from "vue-property-decorator";
import { IProductType } from "@/services/models/productTypes";
import { IOrderStoreRequest } from "@/services/models/order";
import { ProductTypesListRequest } from "@/services/api/ApiProductsTypes";
import { IRegional } from "@/services/models/regionals";
import { RegionalsList } from "@/services/api/ApiRegionals";
import { IBox } from "@/services/models/box";
import QrCode from "@/components/qrcode.vue";
import { BoxShowByCode } from "@/services/api/ApiBox";
import { OrderStoreRequest } from "@/services/api/ApiOrder";
import {
  notificationError,
  notificationSuccess
} from "@/utils/NotificationUtils";
@Component({
  components: { QrCode }
})
export default class NewOrder extends Vue {
  product_types: IProductType[] = [];
  regionals: IRegional[] = [];
  form: IOrderStoreRequest = {};
  boxes: IBox[] = [];
  open_reader = false;
  fields: { key: string; label: string }[] = [
    {
      key: "id",
      label: "Nº"
    },
    {
      key: "status",
      label: `Status`
    },
    {
      key: "actions",
      label: `Ações`
    }
  ];

  async mounted(): Promise<void> {
    await this.load();
  }

  async load(): Promise<void> {
    this.product_types = await ProductTypesListRequest();
    this.regionals = (await RegionalsList(1, 999)).data;
  }

  closeReader(): void {
    this.open_reader = false;
  }

  openReader(): void {
    this.open_reader = true;
  }

  async addBox(code: string): Promise<void> {
    const resp = await BoxShowByCode(code);
    const box: IBox = {
      id: resp.id,
      code: resp.code,
      box_status_id: resp.status.id as number,
      status: resp.status.name,
      liters: resp.liters,
      regional_id: resp.box_regional.id as number,
      regional: ""
    };
    const boxesAux = [...this.boxes];
    boxesAux.push(box);
    this.boxes = boxesAux;
    if (!this.form.boxes) {
      this.form.boxes = [];
    }
    this.form.boxes.push(box.id);
  }

  removeBox(index: number) {
    const boxAux = [...this.boxes];
    boxAux.splice(index, 1);
    this.form?.boxes?.splice(index, 1);
    this.boxes = boxAux;
  }

  log(msg: string): void {
    console.log(msg);
  }

  async sendOrder(): Promise<void> {
    try {
      const rest = await OrderStoreRequest(this.form);
      notificationSuccess("Pedido realizado com sucesso.");
      this.form = {};
    } catch (error) {
      notificationError(
        "Algo de inesperado aconteceu. Tente novamente mais tarde."
      );
    }
  }
}
